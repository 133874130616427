import React from "react";
import Button from "./Button";

const Feature = () => {
  const Features = [
    {
      id: 1,
      title: "FEATURES AND BENEFITS",
      des: "We provide the best fantasy sports id to our players who want to play games with their skills.",
    },
    {
      id: 2,
      title: "Working since 2011",
      des: "We have been working in this field since 2008, so we have a lot more experience than any other newcomers.",
    },
    {
      id: 3,
      title: "High security",
      des: "Security is our top priority, and hence your data and information are safe and not shared at any point!",
    },
  ];

  return (
    <div className="w-full bg-yellow-100 pb-4 pt-20 px-4 md:px-20 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {Features.map((item) => (
        <div key={item.id} className="flex flex-col items-center justify-center gap-4 p-6 bg-white shadow-lg rounded-lg">
          <h1 className="text-2xl md:text-3xl font-semibold uppercase text-center">{item.title}</h1>
          <p className="text-base md:text-lg text-center">{item.des}</p>
          <Button label={"Learn More"}/>
        </div>
      ))}
    </div>
  );
};

export default Feature;
