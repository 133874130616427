import './App.css';
import CricketPoster from './CricketPoster';

function App() {
  return (
    <div className="App">
   <CricketPoster/>    
    </div>
  );
}

export default App;
