import React from "react";
const Button = ({ label }) => {
  return (
    <div className="grid place-items-center">
      <button
        className="bg-gray-100 animate-bounce text-gray-800 px-6 font-semibold py-2 flex items-center gap-3 mt-4 rounded-md"
        onClick={() => window.open("https://wa.link/indraads", "_blank")}
      >
       {label}
      </button>
    </div>
  );
};

export default Button;
